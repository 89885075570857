.header {
  height: 0.88rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out, transform 0.3s ease-in-out;
  .menu-icon-container {
    width: .91rem;
    height: .88rem;
    .menu-icon {
      width: 0.31rem;
      height: .26rem;
      display: block;
      box-sizing: border-box;
      margin: .3rem auto 0;
      border-top: 2px;
      border-bottom: 2px;
      border-color:#fff;
      border-style: solid;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
      }
    }
  }

  .logo {
    width: 1.59rem;
    height: 0.38rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0;
    a {
      display: block;
      height: 100%;
      background: url(~@/assets/images/logo.png) no-repeat 0 0;
      background-size: contain;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }
  &.hidden {
    transform: translateY(-100%);
    // transition: transform 0.3s ease-in-out;
    overflow: hidden;
    box-shadow: none !important;
  }
  &.dark {
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  &.light {
    background: #fff !important;
    box-shadow: 0px 0.04rem 0.1rem 0 rgba(0, 0, 0, 0.04) !important;
    .menu-icon {
      border-color:#000;
      &:after {
        background: #000;
      }
    }
  }
}

.nav-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
  touch-action: none;
  &.on {
    transform: translateX(0);
  }
  &.off {
    transform: translateX(-100%);
  }
  .nav-mask {
    content: '';
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

.nav {
  position: absolute;
  width: 4.2rem;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  font-size: 0.32rem;
  z-index: 2;
  box-shadow: 0.01rem 0 0.2rem 0.05rem rgba(0, 0, 0, 0.1);
  .nav-close {
    height: .88rem;
    overflow: hidden;
  }
  .icon-close {
    float: left;
    width: .91rem;
    height: .88rem;
    border: 0 none;
    background: url(~@/assets/images/close.png) no-repeat center center;
    background-size: .32rem .32rem;
    font-size: 0;
    text-indent: -999em;
    overflow: hidden;
    // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .nav-item-arrow {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    pointer-events: none;
    height: 0.2rem;
    width: 0.4rem;
    margin: 0 0 0 0.15rem;
    transform: translateY(0.07rem);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 0.04rem;
      width: 0.18rem;
      top: 0.05rem;
      left: 0.01rem;
      background: #888;
      transition: backgroundColor 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0.9;
    }
    &::before {
      transform: rotate(-40deg) translateX(0.08rem);
    }
    &::after {
      transform: rotate(40deg) translateX(-0.08rem);
    }
  }

  input:checked + .nav-item-link .nav-item-arrow {
    transform: translateY(-0.05rem);
    &::before {
      transform: rotate(40deg) translateX(0.08rem);
    }
    &::after {
      transform: rotate(-40deg) translateX(-0.08rem);
    }
    &::before,
    &::after {
      opacity: 1;
    }
  }

  input:checked ~ .child-list {
    max-height: 5rem;
  }

  li {
    padding: .1rem 0;
    overflow: hidden;
    position: relative;
    input {
      display: none;
    }
  }

  .child-list {
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    .nav-item-link {
      padding-left: 1.2rem;
      color: #666;
    }
  }

  .nav-child-item-active {
    font-weight: 700;
  }

  .nav-item-link {
    display: block;
    height: .92rem;
    line-height: .92rem;
    color: #333;
    padding-left: .8rem;
    text-decoration: none;
    position: relative;
    // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &-active {
      font-weight: 700;
    }
  }
}
