.back-to-top {
  position: fixed;
  z-index: 999999;
  right: 48px;
  bottom: 50px;
  width: 76px;
  height: 76px;
  cursor: pointer;
  font-size: 0;
  opacity: 0;
  transition: opacity .3s ease-in-out, bottom .2s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    width: 54px;
    height: 54px;
    box-sizing: border-box;
    border: 2px solid #fff;
    background: #F9C700;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::before {
    content: '';
    display: block;
    width: 18px;
    height: 29px;
    margin: 15px auto 0;
    background: url(~@/assets/images/backtotop@2x.png) no-repeat 0 0;
    background-size: contain;
  }
  span {
    display: block;
    font-family: HeadlineA;
    font-size: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: #333;
  }
  &.visible {
    opacity: 1;
  }
}
