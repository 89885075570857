html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
#root{
	overflow: hidden;
}
body {
	line-height: 1;
	overflow: auto;
	font-family: Gilroy-regular,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a:active{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
	min-height: 100%;
}

// font-face
@font-face {
	font-family: 'HeadlineA';
	src: url('~@/assets/font/HeadlineA.woff') format('woff'),
			 url('~@/assets/font/HeadlineA.woff2') format('woff2'),
			 url('~@/assets/font/HeadlineA.svg#HeadlineA') format('svg'),
			 url('~@/assets/font/HeadlineA.eot'),
			 url('~@/assets/font/HeadlineA.eot?#iefix') format('embedded-opentype'),
			 url('~@/assets/font/HeadlineA.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DINAlternateBold';
	src: url('~@/assets/font/DINAlternateBold.woff') format('woff'),
			 url('~@/assets/font/DINAlternateBold.woff2') format('woff2'),
			 url('~@/assets/font/DINAlternateBold.svg#DINAlternateBold') format('svg'),
			 url('~@/assets/font/DINAlternateBold.eot'),
			 url('~@/assets/font/DINAlternateBold.eot?#iefix') format('embedded-opentype'),
			 url('~@/assets/font/DINAlternateBold.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bebas Neue';
  src: url('~@/assets/font/BebasNeue.woff') format('woff'),
	     url('~@/assets/font/BebasNeue.woff2') format('woff2'),
			 url('~@/assets/font/BebasNeue.svg#BebasNeue') format('svg'),
			 url('~@/assets/font/BebasNeue.eot'),
	     url('~@/assets/font/BebasNeue.eot?#iefix') format('embedded-opentype'),
       url('~@/assets/font/BebasNeue.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@import url(~@/assets/styles/slick.css);
