.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  // height: .96rem;
  box-sizing: border-box;
  padding: .25rem 1.2rem;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: background .2s cubic-bezier(.645,.045,.355,1),transform .3s cubic-bezier(.645,.045,.355,1);
  user-select: none;
  .logo {
    width: 1.59rem;
    height: .38rem;
    padding: .04rem;
    font-size: 0;
    a {
      display: block;
      height: 100%;
      background: url(~@/assets/images/logo.png) no-repeat 0 0;
      background-size: contain;
    }
  }
  .join-us {
    width: 1.24rem;
    margin-left: .84rem;
    height: .46rem;
    &-btn {
      display: flex;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid #F9C700;
      border-radius: .5rem;
      font-size: .16rem;
      justify-content: center;
      align-items: center;
      color: #F9C700;
    }
  }
  .nav {
    flex: 1;
    line-height: .46rem;
    font-size: 0;
    text-align: right;
  }
  .nav-item {
    display: inline-block;
    font-size: .18rem;
    vertical-align: top;
    &-link {
      height: .46rem;
      padding: 0 .3rem;
      text-align: center;
      a, span {
        vertical-align: middle;
        display: inline-block;
        cursor: pointer;
        height: 100%;
        &:hover {
          opacity: 1;
        }
      }
      &-active {
        font-weight: 700;
        opacity: 1;
      }
    }
    
    &-arrow {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      pointer-events: none;
      height: .12rem;
      width: .12rem;
      margin: 0 0 0 .05rem;
      transform: translateY(.02rem);
      transition: transform .3s cubic-bezier(.645,.045,.355,1);
      &::before,
      &::after {
        content: '';
        position: absolute;
        height: .02rem;
        width: .1rem;
        top: .05rem;
        left: .01rem;
        background: #fff;
        border-radius: .02rem;
        transition: background .2s cubic-bezier(.645,.045,.355,1),transform .3s cubic-bezier(.645,.045,.355,1),top .3s cubic-bezier(.645,.045,.355,1);
        opacity: .9;
      }
      &::before {
        transform: rotate(-45deg) translateX(.04rem);
      }
      &::after {
        transform: rotate(45deg) translateX(-.04rem);
      }
    }
    .nav-child-list {
      max-height: 0;
      overflow: hidden;
      transition: all .3s cubic-bezier(.645,.045,.355,1);
    }
    .nav-child-item {
      padding: 0 .3rem;
      height: .46rem;
      line-height: .46rem;
      position: relative;
      a {
        color: #fff;
        position: absolute;
        opacity: 0;
        left: .3rem;
        top: 0;
        white-space: nowrap;
        color: #666;
        transition: all .3s cubic-bezier(.645,.045,.355,1);
      }
      &-active {
        font-weight: 700;
      }
    }
  }

  a, span {
    text-decoration: none;
    opacity: .8;
    transition: all .2s cubic-bezier(.645,.045,.355,1);
  }

  &.dark {
    background: rgba(0, 0, 0, .4);
    box-shadow: 0 0 .1rem 0 rgba(0, 0, 0, .1);
    color: #fff;
    .nav-item-link {
      a, span {
        color: #fff;
      }
    }
    .nav-item-arrow {
      &::before,
      &::after {
        background: #fff;
      }
    }
  }
  &.light {
    background: #fff!important;
    box-shadow: 0px .04rem .1rem 0 rgba(0, 0, 0, 0.04)!important;
  }
  &.hover,
  &.light {
    .nav-item-link {
      a, span {
        color: #000;
      }
    }
    .nav-item-arrow {
      &::before,
      &::after {
        background: #000;
      }
    }
  }

  &.hover {
    background: rgba(255, 255, 255, .9)!important;
    .nav-item {
      .nav-child-list {
        padding: .05rem 0;
        max-height: 5rem;
      }
      .nav-child-item {
        a {
          opacity: 1;
        }
      }
      &-arrow {
        transform: translateY(-.04rem);
        &::before {
          transform: rotate(45deg) translateX(.04rem);
        }
        &::after {
          transform: rotate(-45deg) translateX(-.04rem);
        }
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }
  &.hidden {
    transform: translateY(-100%);
    overflow: hidden;
    box-shadow: none!important;
  }
}
